<tkui-overlay #overlay
              [theme]="'light'"
              [closeButton]="true">
  <div aria-live="polite">
    <ng-content></ng-content>
  </div>
  <div class="modal__buttons">
    <tkui-button *ngIf="content?.cancelButtonLabel"
                 class="modal__button"
                 viewType="secondary"
                 [fullWidth]="true"
                 [label]="content?.cancelButtonLabel"
                 (click)="onCancelButtonClick()">
    </tkui-button>

    <tkui-button *ngIf="content?.confirmButtonLabel"
                 class="modal__button"
                 viewType="primary"
                 [fullWidth]="true"
                 [label]="content?.confirmButtonLabel"
                 (click)="onConfirmButtonClick()">
    </tkui-button>
  </div>

</tkui-overlay>
