import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UserLoginInterface, UserSearchResultInterface} from '../../../../shared/interfaces/user.interface';
import {UserConstants} from '../../../../shared/constants/user.constants';
import {DeviceConstants} from '../../../../shared/constants/device.constants';
import {DateFormatUtility} from '../../../../shared/utilities/date-format-utility';
import {ModalComponent} from '../../../../components/modal/modal.component';
import {UserService} from '../../../../shared/services/user.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'tkcc-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit, OnDestroy {

  protected readonly dateFormatUtility = DateFormatUtility;
  protected readonly userConstants = UserConstants;

  @Input() appEndpointPath: string;
  @Input() content: any;
  @Input() userId: string;

  @Output() isUserActive: EventEmitter<boolean> = new EventEmitter();
  @Output() showUserDetails: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('accountDeleteModal', {static: true}) modalRef: ModalComponent;

  public loginData: UserLoginInterface;
  public userData: UserSearchResultInterface;

  private userLoginDataSubscription: Subscription;
  private userDataSubscription: Subscription;

  constructor(private readonly userService: UserService) {
  }

  ngOnInit(): void {
    this.userService.appEndpointPath = this.appEndpointPath;
    this.initUserDetails(this.userId);
  }

  ngOnDestroy(): void {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }

    if (this.userLoginDataSubscription) {
      this.userLoginDataSubscription.unsubscribe();
    }
  }

  public get modalTitle(): string {
    return this.content?.userInformation.accountDisableModal.title.replace('{{userId}}', this.userId);
  }

  public get activityStatus(): string {
    return this.userData.disabledDate ? this.content?.common.accountDisableStatus : this.content.common.accountActiveStatus;
  }

  public initUserDetails(userId: string): void {
    this.userDataSubscription = this.userService.getUserDetailsById(userId).subscribe(
      (data) => {
        this.userData = data;
      }
    );
    this.userLoginDataSubscription = this.userService.getUserLoginDetailsById(userId).subscribe(
      (data) => {
        this.loginData = data;
      }
    );
  }

  public getUserEmail(email: string): string {
    return email ? email : this.content?.common.noData;
  }

  public getUserFullName(firstName: string, lastName: string): string {
    return lastName && firstName ? lastName + ', ' + firstName : this.content?.common.noData;
  }

  public getUserAge(formattedAge: string): string {
    return formattedAge ? formattedAge.replace('~', '') : this.content?.common.noData;
  }

  public getFormattedDate(date: string): string {
    return date ? this.dateFormatUtility.getLocalFormattedDate(date) : this.content?.common.notConfirmed;
  }

  public getFormattedDateTime(date: string): string {
    return date ? this.dateFormatUtility.getLocalFormattedDateTime(date) : this.content?.common.notConfirmed;
  }

  public getDeviceInformation(deviceModel: string, manufactureName: string): string {
    let operationSystem: string;

    if (manufactureName === 'Apple Inc.') {
      operationSystem = DeviceConstants.MANUFACTURE_OS.APPLE;
    } else {
      operationSystem = DeviceConstants.MANUFACTURE_OS.ANDROID;
    }

    return manufactureName || deviceModel ? manufactureName + ', ' + operationSystem + ', ' + deviceModel : this.content?.common.noData;
  }

  public goBackToSearchResults(): void {
    this.userData = null;
    this.showUserDetails.emit(false);
  }

  public openModal(): void {
    this.modalRef.openModal();
  }

  public disableUserAccount(): void {
    this.userService.disableUserAccount(this.userId).subscribe(() => {
      this.initUserDetails(this.userId);
    });
  }
}
