<ng-container *ngIf="content && userId">
  <form [formGroup]="formGroup">
    <tkui-form-input class="notes__form"
                     [formControlName]="content.notesInput.formControlName"
                     [type]="'textarea'"
                     [isInvalid]="currentErrors?.note?.isInvalid"
                     [errorMessage]="currentErrors?.note?.error"
                     (input)="toggleButton($event)">
    </tkui-form-input>
    <div class="notes__button-container">
      <tkui-button [label]="content.notesInput.buttonLabel"
                   [viewType]="'primary'"
                   [disabled]="disableButton"
                   [showBusyIndicator]="showSendButtonBusy"
                   (click)="validateAndSaveNote()">
      </tkui-button>
    </div>
  </form>
  <table *ngIf="notes.length > 0"
         class="notes__table">
    <thead>
    <tr>
      <th>{{content.notesTable.author}}</th>
      <th>{{content.notesTable.creationDate}}</th>
      <th>{{content.notesTable.note}}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let note of notes">
      <td>{{note.createdBy}}</td>
      <td>{{formatDateTime(note.createdDate)}}</td>
      <td>{{note.note}}</td>
    </tr>
    </tbody>
  </table>
</ng-container>

