import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UserSearchResultInterface} from '../../../../shared/interfaces/user.interface';
import {DateFormatUtility} from '../../../../shared/utilities/date-format-utility';

@Component({
  selector: 'tkcc-search-results-list',
  templateUrl: './search-results-list.component.html',
  styleUrls: ['./search-results-list.component.scss']
})
export class SearchResultsListComponent {

  @Input() content: any;
  @Input() users: UserSearchResultInterface[];

  @Output() getUserId: EventEmitter<string> = new EventEmitter();

  constructor() {
  }

  public showDetails(userId: string): void {
    this.getUserId.emit(userId);
  }

  public formatDate(date: string): string {
    return DateFormatUtility.getLocalFormattedDate(date);
  }
}
