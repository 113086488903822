import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {UserDetailsNote} from '../../../../../shared/interfaces/user.interface';
import {DateFormatUtility} from '../../../../../shared/utilities/date-format-utility';
import {UserService} from '../../../../../shared/services/user.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'tkcc-user-details-notes',
  templateUrl: './user-details-notes.component.html',
  styleUrls: ['./user-details-notes.component.scss']
})
export class UserDetailsNotesComponent implements OnInit, OnDestroy {

  @Input() content: any;
  @Input() userId: string;

  public currentErrors: any = {};
  public disableButton = true;
  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    note: new UntypedFormControl('',
      [Validators.required,
        Validators.minLength(2),
        Validators.maxLength(1000)]
    ),
  });

  public notes: UserDetailsNote[] = [];
  public showSendButtonBusy = false;

  private userNotesSubscription: Subscription;

  constructor(private readonly userService: UserService) {
  }

  ngOnInit(): void {
    this.getNotes(this.userId);
  }

  ngOnDestroy(): void {
    if (this.userNotesSubscription) {
      this.userNotesSubscription.unsubscribe();
    }
  }

  public toggleButton(event): void {
    if (event.target.value.length > 1) {
      this.disableButton = false;
    } else {
      this.disableButton = true;
    }
  }

  public validateAndSaveNote(): void {
    const note = this.formGroup.get('note');
    if (note.errors) {
      this.currentErrors.note = {
        isInvalid: true,
        error: this.content.notesInput.errors.maxLengthValidation.replace('{{actualLength}}', note.errors.maxlength.actualLength),
      }
    } else {
      this.currentErrors.note = null;
      this.sendNote(note);
    }
  }

  public formatDateTime(date: string): string {
    return DateFormatUtility.getLocalFormattedDateTime(date);
  }

  private getNotes(userId: string): any {
    this.userNotesSubscription = this.userService.getUserDetailsNotes(userId).subscribe(
      (data) => {
        data.notes.sort((a, b) => {
          return DateFormatUtility.isDateBefore(a.createdDate, b.createdDate) ? 1 : -1;
        });
        this.notes = data.notes;
      }
    );
  }

  private sendNote(noteInputField: any): void {
    this.showSendButtonBusy = true;
    this.userService.postUserDetailsNotes(this.userId, noteInputField.value).subscribe(() => {
      this.getNotes(this.userId);
      this.showSendButtonBusy = false;
      this.formGroup.controls['note'].setValue(null);
    }, (error) => {
      if (error && error.status === 400) {
        this.currentErrors.note = {
          isInvalid: true,
          error: this.content.notesInput.errors.maxLength,
        }
      }
    });
  }
}
