<ng-container *ngIf="content">
  <form [formGroup]="formGroup">
    <div class="search-panel-container">
      <div class="search-panel__input">
        <tkui-form-input id="{{content.searchInputUser.formControlName}}"
                         [formControlName]="content.searchInputUser.formControlName"
                         [label]="content.searchInputUser.title">
        </tkui-form-input>
        <a *ngIf="formGroup.controls['searchTerm'].value !== null"
           class="search-panel__input--close-svg"
           (click)="resetInput('searchTerm')">
          <svg viewBox="0 0 24 24"
               width="100%"
               height="100%">
            <use xlink:href="./assets/icons/close_icon.svg#icon"></use>
          </svg>
        </a>
      </div>
      <div class="search-panel__input">
        <tkui-form-input id="{{content.searchInputDate.formControlName}}"
                         [formControlName]="content.searchInputDate.formControlName"
                         [label]="content.searchInputDate.title"
                         [placeholder]="content.searchInputDate.placeholder"
                         [maxLength]="10">
        </tkui-form-input>
        <a *ngIf="formGroup.controls['registrationDate'].value !== null"
           class="search-panel__input--close-svg"
           (click)="resetInput('registrationDate')">
          <svg viewBox="0 0 24 24"
               width="100%"
               height="100%">
            <use xlink:href="./assets/icons/close_icon.svg#icon"></use>
          </svg>
        </a>
      </div>
    </div>
  </form>
</ng-container>
