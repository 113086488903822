import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';

const USER_ACCOUNT_ENDPOINTS = {
  DETAILS: '/v1/account/details', // GET, DELETE
  DETAILS_NOTES: '/v1/account/details/notes', // GET, POST, DELETE
  LOGIN_DETAILS: '/v1/account/details/login', // GET
};

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public appEndpointPath: string;

  constructor(private readonly httpClient: HttpClient) {
  }

  public getUserDetailsById(userId: string): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}` + this.appEndpointPath + USER_ACCOUNT_ENDPOINTS.DETAILS + '?accountId=' + userId);
  }

  public getUserLoginDetailsById(userId: string): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}` + this.appEndpointPath + USER_ACCOUNT_ENDPOINTS.LOGIN_DETAILS + '?accountId=' + userId);
  }

  public getUserDetailsNotes(userId: string): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}` + this.appEndpointPath + USER_ACCOUNT_ENDPOINTS.DETAILS_NOTES + '?accountId=' + userId);
  }

  public postUserDetailsNotes(userId: string, note: string): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}` + this.appEndpointPath + USER_ACCOUNT_ENDPOINTS.DETAILS_NOTES, {
      accountId: userId,
      note: note
    });
  }

  public disableUserAccount(userId: string): Observable<any> {
    return this.httpClient.delete(`${environment.apiUrl}` + this.appEndpointPath + USER_ACCOUNT_ENDPOINTS.DETAILS + '?accountId=' + userId);
  }

}
