<ng-container *ngIf="content">
  <div class="grid-root-container main-container">
    <div class="grid-item">
      <div class="login">
        <h3 class="login__title">{{content.title}}</h3>
        <ng-container *ngIf="!(hasValidAccessToken)">
          <div class="login__button-wrapper">
            <tkui-button class="login__button"
                         [label]="content.submitButtonLabel"
                         (click)="login()">
            </tkui-button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
