<div *ngIf="content"
     class="pagination">
  <button class="pagination__button pagination__button__previous"
          tkuiKeyboardAccess
          [class.pagination__button--disabled]="currentPage < 2"
          [attr.aria-hidden]="currentPage < 2"
          [attr.aria-label]="prevPageAriaLabel"
          (click)="onClickPrevPage()">

    <svg class="icon"
         viewBox="0 0 13 18" width="100%" height="100%">
      <use xlink:href="assets/icons/arrow.svg#icon"></use>
    </svg>
  </button>

  <span>{{content.page}} {{currentPage}}  /  {{totalPages}}</span>

  <button class="pagination__button pagination__button__next"
          tkuiKeyboardAccess
          [class.pagination__button--disabled]="isLastPage"
          [attr.aria-hidden]="isLastPage"
          [attr.aria-label]="nextPageAriaLabel"
          (click)="onClickNextPage()">

    <svg class="icon"
         viewBox="0 0 13 18" width="100%" height="100%">
      <use xlink:href="assets/icons/arrow.svg#icon"></use>
    </svg>

  </button>
</div>

