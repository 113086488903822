export const UserConstants = {
  GENDER: {
    MALE: 'männlich',
    FEMALE: 'weiblich',
    DIVERSE: 'divers',
  },
  USER_ROLES: {
    TK_USER: 'TK_USER',
    TK_GUEST: 'TK_GUEST'
  },
  USER_ROLE_STRINGS: {
    TK_USER: 'TK Versicherter',
    TK_GUEST: 'Gast'
  }
};
