<header *ngIf="content">
  <div class="header__container">
    <a class="header__logo"
       [title]="content.logoTitle"
       [href]="logoLink">
      <img [alt]="content.logoAlt"
           [src]="imageNameWithText"/>
    </a>
    <nav *ngIf="(hasValidAccessToken && customerCareUserRoles)"
         class="grid-item">
      <ul class="header__navigation"
          [class.header__navigation--one]="customerCareUserRoles.length < 2">
        <li *ngFor="let userRole of customerCareUserRoles"
            class="header__navigation-item">
          <a class="header__navigation-link"
             [routerLink]="navigationEntries[userRole]"
             [routerLinkActive]="['header__navigation-link--active']">
            <span class="header__navigation-link-text">{{content.links[userRole]}}</span>
          </a>
        </li>
        <li class="header__navigation-item">
          <a class="header__navigation-link"
             (click)="logout()">
            <span class="header__navigation-link-text">{{content.logoutButtonLabel}}</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</header>
