<ng-container *ngIf="content">
  <div class="grid-root-container main-container">
    <div class="grid-item">
      <div class="dashboard">
        <h3 class="dashboard__title">{{content.title}}</h3>
        <div class="dashboard__links-wrapper">
          <ng-container *ngFor="let userRole of customerCareUserRoles">
            <a [href]="navigationEntries[userRole]">{{content.links[userRole]}}</a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>
