<ng-container *ngIf="content">
  <div class="grid-root-container main-container">
    <div class="grid-item">
      <tkcc-search-panel [content]="content.searchInput"
                         [currentPage]="currentPage"
                         (searchValues)="setSearchValue($event)">
      </tkcc-search-panel>
    </div>
    <div *ngIf="!showUserDetails && users"
         class="grid-item">
      <tkcc-search-results-list *ngIf="users.length > 0"
                                [content]="content.userData"
                                [users]="users"
                                (getUserId)="setUserId($event)">
      </tkcc-search-results-list>

      <div class="user-search__pagination">
        <tkcc-pagination *ngIf="(totalElements > itemsPerPage) && users"
                         [content]="content.pagination"
                         [currentPage]="currentPage"
                         [itemsPerPage]="itemsPerPage"
                         [numberOfItems]="totalElements"
                         (changePage)="onPaginate($event)">
        </tkcc-pagination>
      </div>

      <p *ngIf="users.length === 0"
         class="user-search__no-results">
        {{content.common.noResults}}
      </p>
    </div>
    <div *ngIf="showUserDetails"
         class="grid-item">
      <tkcc-user-details [appEndpointPath]="appEndpointPath"
                         [content]="content.userDetails"
                         [userId]="userId"
                         (showUserDetails)="checkIfShowUserDetails($event)">
      </tkcc-user-details>
    </div>
  </div>
</ng-container>
