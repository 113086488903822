import {AuthConfig} from 'angular-oauth2-oidc';

const callbackPath = '/auth/callback';

export const authConfig: AuthConfig = {
  scope: 'openid profile email offline_access',
  responseType: 'code',
  oidc: true,
  clientId: '235495164762755103@tk-customercare',
  issuer: 'https://dev-tk-customercare-vtldi0.zitadel.cloud',
  redirectUri: window.location.origin + callbackPath,
  postLogoutRedirectUri: window.location.origin,
  requireHttps: true,
};
