import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './modules/login/login.component';
import {SearchUserComponent} from './modules/search-user/search-user.component';
import {AuthGuard} from './shared/guards/auth.guard';
import {DashboardComponent} from './modules/dashboard/dashboard.component';
import {LoginGuard} from './shared/guards/login.guard';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tk-coach-app',
    component: SearchUserComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tk-rauch-frei-app',
    component: SearchUserComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'auth/callback',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
