import {Component, OnDestroy, OnInit} from '@angular/core';
import {ContentService} from '../../shared/services/content.service';
import {RoutesConstants} from '../../shared/constants/routes.constants';
import {Observable, Subscription} from 'rxjs';
import {AuthenticationService} from '../../shared/services/authentication.service';

@Component({
  selector: 'tkcc-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  public content: any;
  public customerCareUserRoles: string[];
  public navigationEntries = RoutesConstants;

  private customerCareUser: Observable<any>;
  private customerCareUserSubscription: Subscription;

  constructor(private readonly authenticationService: AuthenticationService,
              private readonly contentService: ContentService) {
    this.customerCareUser = this.authenticationService.getOIDCUser();
  }

  async ngOnInit(): Promise<void> {
    this.content = await this.contentService.getContent('tkcc-dashboard');
    this.initCustomerCareUserSubscription();
  }

  ngOnDestroy(): void {
    if (this.customerCareUserSubscription) {
      this.customerCareUserSubscription.unsubscribe();
    }
  }

  private initCustomerCareUserSubscription(): void {
    this.customerCareUserSubscription = this.customerCareUser.subscribe((data) => {
      const roles = data.info['urn:zitadel:iam:org:project:roles'];
      this.customerCareUserRoles = Object.keys(roles);
    });
  }

}
