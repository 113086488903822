import {Component, OnDestroy, OnInit} from '@angular/core';
import {ContentService} from '../../shared/services/content.service';
import {Subscription} from 'rxjs';
import {UserSearchService} from '../../shared/services/user-search.service';
import {UserSearchingInterface, UserSearchResultInterface} from '../../shared/interfaces/user.interface';
import {ApiService} from '../../shared/services/api.service';

@Component({
  selector: 'tkcc-search-user',
  templateUrl: './search-user.component.html',
  styleUrls: ['./search-user.component.scss']
})
export class SearchUserComponent implements OnInit, OnDestroy {

  public readonly appEndpointPath: string;

  public content: any;
  public currentPage = 1;
  public itemsPerPage = 15;
  public showUserDetails = false;
  public totalElements: number;
  public userId: string;
  public users: UserSearchResultInterface[];

  private searchValues: UserSearchingInterface;
  private userSearchSubscription: Subscription;

  constructor(private readonly apiService: ApiService,
              private readonly contentService: ContentService,
              private readonly userSearchService: UserSearchService) {
    this.appEndpointPath = this.apiService.getAppEndpoint();
  }

  async ngOnInit(): Promise<void> {
    this.content = await this.contentService.getContent('tkcc-search-user');
    this.userSearchService.appEndpointPath = this.appEndpointPath;
    this.initUserSearchSubscription();
  }

  ngOnDestroy(): void {
    if (this.userSearchSubscription) {
      this.userSearchSubscription.unsubscribe();
    }
  }

  public setUserId(userId: string): void {
    this.userId = userId;
    this.showUserDetails = !!userId;
  }

  public setSearchValue(searchValue: UserSearchingInterface): void {
    this.searchValues = searchValue;
  }

  public checkIfShowUserDetails(showUserDetails: boolean): void {
    this.showUserDetails = showUserDetails;
  }

  public onPaginate(pageNumber): void {
    this.currentPage = pageNumber;
    this.userSearchService.getUserDataByPage(this.searchValues, this.currentPage, this.itemsPerPage);
  }

  private initUserSearchSubscription(): void {
    this.userSearchSubscription = this.userSearchService.searchResults$.subscribe(results => {
        this.users = results.data;
        this.totalElements = results.totalElements;
        if (!this.users) {
          this.showUserDetails = false;
        }
      }
    );
  }
}
