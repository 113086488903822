import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {RoutesConstants} from '../constants/routes.constants';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private readonly router: Router) {
  }

  public getAppEndpoint(): string {
    let appEndpointPath: string;

    if (this.router.url.indexOf(RoutesConstants.CUSTOMER_CARE_USER_TKGC_APP) > -1) {
      appEndpointPath = environment.tkgcAppPath;
    } else {
      appEndpointPath = environment.tkrfAppPath;
    }
    return appEndpointPath
  }
}
