import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {OverlayComponent} from 'tk-ui-components';

@Component({
  selector: 'tkcc-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

  @Input() content: any;

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() confirm: EventEmitter<any> = new EventEmitter();

  @ViewChild('overlay', {static: true}) overlayRef: OverlayComponent;

  constructor() {
  }

  public openModal(): void {
    this.overlayRef.openOverlay();
  }

  public closeModal(): void {
    this.overlayRef.closeOverlay();
  }

  public onConfirmButtonClick(): void {
    this.confirm.emit();
    this.closeModal();
  }

  public onCancelButtonClick(): void {
    this.cancel.emit();
    this.closeModal();
  }

}
