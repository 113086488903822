<div *ngIf="userData && content"
     class="user-details">
  <div class="grid-root-container">
    <div class="grid-item">
      <div class="user-details__header">
        <span class="user-details__header-status"
              [class.user-details__header-status--disable]="userData.disabledDate">
              {{activityStatus}}
        </span>
        <a class="user-details__header-link"
           (click)="goBackToSearchResults()">
          {{content.common.backLinkLabel}}
        </a>
      </div>
    </div>
  </div>
  <div class="grid-root-container">
    <ng-container *ngIf="userData">
      <div class="grid-item--user">
        <h2 class="user-details__title">{{content.userInformation.title}}</h2>
        <table class="user-details__table">
          <tr>
            <th>{{content.userInformation.id}}</th>
            <td>{{userData.id}}</td>
          </tr>
          <tr>
            <th>{{content.userInformation.email}}</th>
            <td>{{getUserEmail(userData.email)}}</td>
          </tr>
          <tr>
            <th>{{content.userInformation.name}}</th>
            <td>{{getUserFullName(userData.firstName, userData.lastName)}}</td>
          </tr>
          <tr>
            <th>{{content.userInformation.age}}</th>
            <td>{{getUserAge(userData.formattedAge)}}</td>
          </tr>
          <tr>
            <th>{{content.userInformation.gender}}</th>
            <td>{{userConstants.GENDER[userData.gender]}}</td>
          </tr>
          <tr>
            <th>{{content.userInformation.partnerID}}</th>
            <td>{{userData.partnerId}}</td>
          </tr>
          <tr>
            <th>{{content.userInformation.role}}</th>
            <td>{{userConstants.USER_ROLE_STRINGS[userData.role]}}</td>
          </tr>
          <tr>
            <th>{{content.userInformation.registrationDate}}</th>
            <td>{{getFormattedDate(userData.registrationDate)}}</td>
          </tr>
          <tr *ngIf="userData.registrationConfirmationDate">
            <th>{{content.userInformation.registrationConfirmedAt}}</th>
            <td>{{getFormattedDate(userData.registrationConfirmationDate)}}</td>
          </tr>
          <tr *ngIf="userData.role === userConstants.USER_ROLES.TK_GUEST">
            <th>{{content.userInformation.validUntil}}</th>
            <td>{{getFormattedDate(userData.validUntil)}}</td>
          </tr>
          <tr *ngIf="userData.disabledDate">
            <th>{{content.userInformation.disabledDate}}</th>
            <td>{{getFormattedDate(userData.disabledDate)}}</td>
          </tr>
        </table>
      </div>
    </ng-container>
    <ng-container *ngIf="loginData">
      <div class="grid-item--login">
        <h2 class="user-details__title">{{content.loginInformation.title}}</h2>
        <table class="user-details__table">
          <tr>
            <th>{{content.loginInformation.firstLogin}}</th>
            <td>{{getFormattedDateTime(loginData.firstLoginDate)}}</td>
          </tr>
          <tr>
            <th>{{content.loginInformation.lastLogin}}</th>
            <td>{{getFormattedDateTime(loginData.lastLoginDate)}}</td>
          </tr>
          <tr>
            <th>{{content.loginInformation.totalLoginCount}}</th>
            <td>{{loginData.totalLoginCount}}</td>
          </tr>
          <tr>
            <th>{{content.loginInformation.daysSinceLastLogin}}</th>
            <td>{{loginData.daysSinceLastLoginCount}}</td>
          </tr>
          <tr>
            <th>{{content.loginInformation.deviceInformation}}</th>
            <td>{{getDeviceInformation(loginData?.deviceModel, loginData?.manufactureName)}}</td>
          </tr>
        </table>
        <div *ngIf="!userData.disabledDate"
             class="user-details__button-container">
          <tkui-button [label]="content.userInformation.accountDisableButtonLabel"
                       [viewType]="'primary'"
                       (click)="openModal()">
          </tkui-button>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="grid-root-container user-details__notes">
    <div class="grid-item">
      <h2 class="user-details__title">{{content.notes.title}}</h2>
      <tkcc-user-details-notes [content]="content.notes"
                               [userId]="userId">
      </tkcc-user-details-notes>
    </div>
  </div>
</div>

<tkcc-modal #accountDeleteModal
            [content]="content?.userInformation.accountDisableModal.buttons"
            (confirm)="disableUserAccount()">
  <h6 *ngIf="modalTitle"
      class="user-details__modal-title">
    {{modalTitle}}
  </h6>
</tkcc-modal>
