import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {AuthConfig, OAuthModule, OAuthStorage} from 'angular-oauth2-oidc';
import {BrowserModule} from '@angular/platform-browser';
import localeDe from '@angular/common/locales/de';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CommonModule, NgIf, registerLocaleData} from '@angular/common';
import {HeaderComponent} from './components/header/header.component';
import {LoginComponent} from './modules/login/login.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TkUiComponentsModule} from 'tk-ui-components';
import {SearchUserComponent} from './modules/search-user/search-user.component';
import {SearchPanelComponent} from './modules/search-user/components/search-panel/search-panel.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {StorageService} from './shared/services/storage.service';
import {StateHandlerService, StateHandlerServiceImpl} from './shared/services/statehandler.service';
import {
  StateHandlerProcessorService,
  StateHandlerProcessorServiceImpl
} from './shared/services/statehandler-processor.service';
import {
  SearchResultsListComponent
} from './modules/search-user/components/search-results-list/search-results-list.component';
import {UserDetailsComponent} from './modules/search-user/components/user-details/user-details.component';
import {
  UserDetailsNotesComponent
} from './modules/search-user/components/user-details/user-details-notes/user-details-notes.component';
import {ModalComponent} from './components/modal/modal.component';
import {AuthInterceptor, JwtInterceptor} from './shared/interceptor/auth.interceptor';
import {DashboardComponent} from './modules/dashboard/dashboard.component';
import {authConfig} from '../authConfigs/auth.config';
import {PaginationComponent} from './components/pagination/pagination.component';

registerLocaleData(localeDe, 'de-DE');

const stateHandlerFn = (stateHandler: StateHandlerService) => {
  return () => {
    return stateHandler.initStateHandler();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HeaderComponent,
    LoginComponent,
    ModalComponent,
    PaginationComponent,
    SearchPanelComponent,
    SearchResultsListComponent,
    SearchUserComponent,
    UserDetailsComponent,
    UserDetailsNotesComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    NgIf,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [
          authConfig.issuer + '/admin/v1',
          authConfig.issuer + '/management/v1',
          authConfig.issuer + '/auth/v1/',
          authConfig.issuer + '/oidc/v1/',
          authConfig.issuer + '/oauth/v2/'
        ],
        sendAccessToken: true,
      },
    }),
    ReactiveFormsModule,
    TkUiComponentsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: stateHandlerFn,
      multi: true,
      deps: [StateHandlerService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: AuthConfig,
      useValue: authConfig,
    },
    {
      provide: StateHandlerProcessorService,
      useClass: StateHandlerProcessorServiceImpl,
    },
    {
      provide: StateHandlerService,
      useClass: StateHandlerServiceImpl,
    },
    {
      provide: OAuthStorage,
      useClass: StorageService,
    },
    {
      provide: LOCALE_ID,
      useValue: 'de_DE'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
