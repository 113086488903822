import {Component, OnInit} from '@angular/core';
import {ContentService} from '../../shared/services/content.service';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {OAuthService} from 'angular-oauth2-oidc';

@Component({
  selector: 'tkcc-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public content: any;
  public hasValidAccessToken = false;

  constructor(private readonly authenticationService: AuthenticationService,
              private readonly contentService: ContentService,
              private readonly oauthService: OAuthService) {
    this.hasValidAccessToken = this.oauthService.hasValidAccessToken();
  }

  async ngOnInit(): Promise<void> {
    this.content = await this.contentService.getContent('tkcc-login');
  }

  public login(): void {
    this.authenticationService.authenticate();
  }

}
