import * as moment from 'moment';

export class DateFormatUtility {

  public static getLocalFormattedDate(date: Date | string): string {
    return moment(date, ['YYYY-MM-DD']).locale('de').format('L');
  }

  public static getLocalFormattedDateTime(date: Date | string): string {
    return moment(date, ['YYYY-MM-DD HH:mm:ss']).format('DD.MM.YYYY HH:mm');
  }

  public static getStandardFormattedDate(date: Date | string): string {
    return moment(date, ['DD.MM.YYYY']).format('YYYY-MM-DD');
  }

  public static isDateBefore(dateOne: Date | string, dateTwo: Date | string): boolean {
    return moment(dateOne).isBefore(moment(dateTwo));
  }
}
