import {Component, OnDestroy, OnInit} from '@angular/core';
import {RoutesConstants} from '../../shared/constants/routes.constants';
import {ContentService} from '../../shared/services/content.service';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {OAuthService} from 'angular-oauth2-oidc';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'tkcc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  public content: any;
  public customerCareUserRoles: string[];
  public hasValidAccessToken = false;
  public imageNameWithText = './assets/images/tk-logo-xs.svg';
  public navigationEntries = RoutesConstants;

  private customerCareUser: Observable<any>;
  private customerCareUserSubscription: Subscription;

  constructor(private readonly authenticationService: AuthenticationService,
              private readonly contentService: ContentService,
              private readonly oauthService: OAuthService) {
    this.hasValidAccessToken = this.oauthService.hasValidAccessToken();
  }

  async ngOnInit(): Promise<void> {
    this.content = await this.contentService.getContent('tkcc-header');

    this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
      if (this.hasValidAccessToken) {
        this.customerCareUser = this.authenticationService.getOIDCUser();
        this.initCustomerCareUserSubscription();
      } else {
        this.oauthService.initCodeFlow();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.customerCareUserSubscription) {
      this.customerCareUserSubscription.unsubscribe();
    }
  }

  public get logoLink(): string {
    return this.hasValidAccessToken ? 'dashboard' : '/'
  }

  public logout(): void {
    this.authenticationService.logout();
  }

  private initCustomerCareUserSubscription(): void {
    this.customerCareUserSubscription = this.customerCareUser.subscribe((data) => {
      const roles = data.info['urn:zitadel:iam:org:project:roles'];
      this.customerCareUserRoles = Object.keys(roles);
    });
  }
}
