import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {debounceTime, Subscription} from 'rxjs';
import {UserSearchingInterface, UserSearchInterface} from '../../../../shared/interfaces/user.interface';
import {UserSearchService} from '../../../../shared/services/user-search.service';
import {DateFormatUtility} from '../../../../shared/utilities/date-format-utility';

@Component({
  selector: 'tkcc-search-panel',
  templateUrl: './search-panel.component.html',
  styleUrls: ['./search-panel.component.scss'],
})
export class SearchPanelComponent implements OnInit, OnDestroy {

  @Input() content: any;
  @Input() currentPage: number;

  @Output() searchValues: EventEmitter<UserSearchingInterface> = new EventEmitter();

  private germanDatePattern = '^\\s*(3[01]|[12][0-9]|0?[1-9])\\.(1[012]|0?[1-9])\\.((?:19|20)\\d{2})\\s*$';

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    searchTerm: new UntypedFormControl(null),
    registrationDate: new UntypedFormControl(null, Validators.compose(
      [Validators.pattern(this.germanDatePattern), Validators.nullValidator],
    )),
  });

  private itemsPerPage = 15;
  private valueChangesSubscription: Subscription;

  constructor(private readonly userSearchService: UserSearchService) {
  }

  ngOnInit(): void {
    this.initValueChangeSubscription();
  }

  ngOnDestroy(): void {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
  }

  public resetInput(formControlName: string): void {
    this.formGroup.controls[formControlName].setValue(null);
    document.getElementById(formControlName).focus();
    this.currentPage = 0;
    const data: UserSearchInterface = {data: [], totalElements: 0};
    this.userSearchService.setSearchResults(data);
  }

  private initValueChangeSubscription(): void {
    this.valueChangesSubscription = this.formGroup.valueChanges
      .pipe(debounceTime(300))
      .subscribe((formValues: UserSearchingInterface) => {
        if ((formValues.searchTerm && formValues.searchTerm.length > 0)
          || (this.formGroup.controls['registrationDate'].valid && formValues.registrationDate && formValues.registrationDate.length >= 8)) {

          if (formValues.registrationDate) {
            formValues.registrationDate = DateFormatUtility.getStandardFormattedDate(formValues.registrationDate);
            this.currentPage = 0;
          }
          this.searchValues.emit(formValues);
          this.userSearchService.getUserDataByPage(formValues, this.currentPage, this.itemsPerPage);
        }
      });
  }
}
