<div *ngIf="users"
     class="search-results-list">
  <table class="search-results-list__table">
    <thead>
    <tr>
      <th></th>
      <th>{{content.id}}</th>
      <th>{{content.name}}</th>
      <th>{{content.email}}</th>
      <th>{{content.partnerID}}</th>
      <th>{{content.registrationDate}}</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let user of users">
      <td>
        <span class="search-results-list__item-status"
              [class.search-results-list__item-status--disable]="user.disabledDate">
        </span>
      </td>
      <td>{{user.id}}</td>
      <td>{{user.lastName + ', '}} {{user.firstName}}</td>
      <td>{{user.email}}</td>
      <td>{{user.partnerId}}</td>
      <td>{{formatDate(user.registrationDate)}}</td>
      <td>
        <a (click)="showDetails(user.id)">
          {{content.details}}
        </a>
      </td>
    </tr>
    </tbody>
  </table>
</div>
