import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'tkcc-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {

  @Input() content: any;
  @Input() currentPage: number;
  @Input() itemsPerPage: number;
  @Input() numberOfItems: number;

  @Output() changePage = new EventEmitter<Number>();

  constructor() {
  }

  public get totalPages(): number {
    return Math.ceil(this.numberOfItems / this.itemsPerPage);
  }

  public get isLastPage(): boolean {
    return (this.currentPage * this.itemsPerPage) >= this.numberOfItems;
  }

  public get prevPageAriaLabel(): string {
    return this.content && this.content.ariaLabel &&
      this.content.ariaLabel.replace('{{pageNumber}}', this.currentPage - 1);
  }

  public get nextPageAriaLabel(): string {
    return this.content && this.content.ariaLabel &&
      this.content.ariaLabel.replace('{{pageNumber}}', this.currentPage + 1);
  }

  public onClickPrevPage(): void {
    if (this.currentPage !== 1) {
      this.changePage.emit(this.currentPage - 1);
    }
  }

  public onClickNextPage(): void {
    if (!this.isLastPage) {
      this.changePage.emit(this.currentPage + 1);
    }
  }

}
